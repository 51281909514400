import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.css'
import Marketplace from './views/marketplace'
import HomeFuild from './views/home-fuild'
import Privacypolicy from './views/privacypolicy'
import Presale from './views/presale'
import Ambassador from './views/ambassador'
import NotFound from './views/not-found'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={Marketplace} exact path="/marketplace" />
        <Route component={HomeFuild} exact path="/" />
        <Route component={Privacypolicy} exact path="/privacypolicy" />
        <Route component={Presale} exact path="/presale" />
        <Route component={Ambassador} exact path="/ambassador" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
