import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Logos1 from '../components/logos1'
import FAQ11 from '../components/faq11'
import './marketplace.css'

const Marketplace = (props) => {
  return (
    <div className="marketplace-container">
      <Helmet>
        <title>marketplace - exported project</title>
        <meta property="og:title" content="marketplace - exported project" />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name1"></Navbar>
      <div className="marketplace-logos3">
        <Logos1 rootClassName="logos1-root-class-name"></Logos1>
      </div>
      <div className="marketplace-faq5">
        <FAQ11
          content1="The MINT NFT platform as well as the gaming platform will be found on this page, it is currently under construction."
          rootClassName="faq11-root-class-name"
        ></FAQ11>
      </div>
      <div className="marketplace-footer6"></div>
    </div>
  )
}

export default Marketplace
