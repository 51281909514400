import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './navbar.css'

const Navbar = (props) => {
  return (
    <div className={`navbar-container ${props.rootClassName} `}>
      <div className="navbar-max-width">
        <Link to="/" className="navbar-navlink">
          <img
            alt="image"
            src="/whiskerlogooffbois11332-0xga-200h.png"
            className="navbar-logo"
          />
        </Link>
        <div className="navbar-container1">
          <div className="navbar-links">
            <span className="navbar-text navbar-link">Marketplace</span>
            <a
              href="https://medium.com/@witcoinwhisker"
              target="_blank"
              rel="noreferrer noopener"
              className="navbar-link"
            >
              News
            </a>
            <a
              href="https://witwhisker.com/#RD"
              className="navbar-link1 navbar-link"
            >
              Roadmap
            </a>
            <Link to="/ambassador" className="navbar-navlink1 navbar-link">
              Ambassador
            </Link>
            <a
              href="https://witwhisker.com/#SOC"
              className="navbar-link2 navbar-link"
            >
              Social
            </a>
          </div>
          <div
            data-thq="thq-dropdown"
            className="navbar-thq-dropdown list-item"
          >
            <div
              data-thq="thq-dropdown-toggle"
              className="navbar-dropdown-toggle"
            >
              <span className="navbar-text1">WHISKER</span>
              <div
                data-thq="thq-dropdown-arrow"
                className="navbar-dropdown-arrow"
              >
                <svg viewBox="0 0 1024 1024" className="navbar-icon">
                  <path d="M426 726v-428l214 214z" className=""></path>
                </svg>
              </div>
            </div>
            <ul data-thq="thq-dropdown-list" className="navbar-dropdown-list">
              <li data-thq="thq-dropdown" className="navbar-dropdown list-item">
                <div
                  data-thq="thq-dropdown-toggle"
                  className="navbar-dropdown-toggle1"
                >
                  <Link to="/marketplace" className="navbar-navlink2">
                    MARKETPLACE
                  </Link>
                </div>
              </li>
              <li
                data-thq="thq-dropdown"
                className="navbar-dropdown1 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="navbar-dropdown-toggle2"
                >
                  <a
                    href="https://medium.com/@witcoinwhisker"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="navbar-link3"
                  >
                    <span className="">NEWS</span>
                    <br className=""></br>
                  </a>
                </div>
              </li>
              <li
                data-thq="thq-dropdown"
                className="navbar-dropdown2 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="navbar-dropdown-toggle3"
                >
                  <Link to="/presale" className="navbar-navlink3">
                    PRESALE
                  </Link>
                </div>
              </li>
              <li
                data-thq="thq-dropdown"
                className="navbar-dropdown3 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="navbar-dropdown-toggle4"
                >
                  <a
                    href="https://witwhisker.com/#SOC"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="navbar-link4"
                  >
                    SOCIAL
                  </a>
                </div>
              </li>
              <li
                data-thq="thq-dropdown"
                className="navbar-dropdown4 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="navbar-dropdown-toggle5"
                >
                  <Link to="/ambassador" className="navbar-navlink4">
                    AMBASSADOR
                  </Link>
                </div>
              </li>
              <li
                data-thq="thq-dropdown"
                className="navbar-dropdown5 list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="navbar-dropdown-toggle6"
                >
                  <a
                    href="https://witwhisker.com/#RD"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="navbar-link5"
                  >
                    ROADMAP
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <Link to="/presale" className="navbar-cta-btn navbar-link">
          Presale
        </Link>
      </div>
    </div>
  )
}

Navbar.defaultProps = {
  rootClassName: '',
}

Navbar.propTypes = {
  rootClassName: PropTypes.string,
}

export default Navbar
