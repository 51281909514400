import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './baspage.css'

const Baspage = (props) => {
  return (
    <div className={`baspage-container ${props.rootClassName} `}>
      <Link to="/privacypolicy" className="baspage-navlink">
        <div className="baspage-pp">
          <span className="baspage-text">PrivacyPolicy</span>
          <span className="baspage-text1">PrivacyPolicy</span>
        </div>
      </Link>
      <a
        href="https://witwhisker.com/PDFWP.pdf"
        target="_blank"
        rel="noreferrer noopener"
        className="baspage-link"
      >
        <div className="baspage-wp">
          <span className="baspage-text2">{props.text}</span>
          <span className="baspage-text3">{props.text1}</span>
        </div>
      </a>
      <a
        href="mailto:Contact@witwhisker.com?subject=For the best Base project in the world"
        className="baspage-mail"
      >
        {props.mail}
      </a>
      <div className="baspage-j-oin-wit">
        <span className="baspage-text4">{props.text2}</span>
        <span className="baspage-text5">{props.text3}</span>
      </div>
      <div id="SOCIAL" className="baspage-whisker-big">
        <span className="baspage-text6">{props.text4}</span>
        <span className="baspage-text7">{props.text5}</span>
      </div>
      <img alt={props.tAPEAlt} src={props.tAPESrc} className="baspage-tape" />
    </div>
  )
}

Baspage.defaultProps = {
  rectangle9Src2:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/abd7273e-a1d8-480a-a685-990544bc078e/fc8e4ab9-a11d-4819-8a5a-149bd092f637?org_if_sml=1884&force_format=original',
  rootClassName: '',
  rectangle10Alt: 'Rectangle101302',
  rectangle10Alt1: 'Rectangle101302',
  linkIAMTHEBESTMEMECOIN241: 'https://twitter.com/Whisker_Witcoin',
  rectangle10Src2:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/abd7273e-a1d8-480a-a685-990544bc078e/501803eb-bcdc-4103-a829-ab5455f68942?org_if_sml=1744&force_format=original',
  rectangle9Alt1: 'Rectangle91302',
  text5: 'WHISKER',
  linkRectangle10: 'https://t.me/+fcFRXCCEWPI0Y2U0',
  text2: 'JOIN THE $WIT COMMUNITY',
  rectangle9Alt: 'Rectangle91302',
  rectangle9Src:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/abd7273e-a1d8-480a-a685-990544bc078e/0c97f385-24d7-487a-8d8e-4a964d131c69?org_if_sml=1887&force_format=original',
  rectangle9Alt2: 'Rectangle91302',
  text: 'WHITEPAPER',
  iAMTHEBESTMEMECOIN251Alt: 'IAMTHEBESTMEMECOIN2511302',
  tAPEAlt: 'image',
  text4: 'WHISKER',
  text3: 'JOIN THE $WIT COMMUNITY',
  rectangle10Alt2: 'Rectangle101302',
  rectangle10Src1:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/abd7273e-a1d8-480a-a685-990544bc078e/d8b85950-cfce-4508-84be-68d73a1e9c9a?org_if_sml=1711&force_format=original',
  iAMTHEBESTMEMECOIN251Src:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/abd7273e-a1d8-480a-a685-990544bc078e/be4ddd48-5e87-4830-907c-1390cef32745?org_if_sml=12725&force_format=original',
  rectangle10Src:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/abd7273e-a1d8-480a-a685-990544bc078e/4a677ac1-b30e-43fa-8dd8-3015c17cb8a0?org_if_sml=1736&force_format=original',
  iAMTHEBESTMEMECOIN281Alt: 'IAMTHEBESTMEMECOIN2811302',
  tAPESrc: '/whisker%20(31)-1100h.png',
  iAMTHEBESTMEMECOIN241Src:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/abd7273e-a1d8-480a-a685-990544bc078e/314010d7-9db8-4a5b-875d-812a541d10d2?org_if_sml=12463&force_format=original',
  text1: 'WHITEPAPER',
  linkIAMTHEBESTMEMECOIN281: 'https://discord.com/invite/unjG6yz4T3',
  linkRectangle9: 'https://twitter.com/Whisker_Witcoin',
  linkIAMTHEBESTMEMECOIN251: 'https://t.me/+fcFRXCCEWPI0Y2U0',
  rectangle9Src1:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/abd7273e-a1d8-480a-a685-990544bc078e/0c97f385-24d7-487a-8d8e-4a964d131c69?org_if_sml=1887&force_format=original',
  linkRectangle101: 'https://discord.com/invite/unjG6yz4T3',
  mail: 'Contact@witwhisker.com',
  iAMTHEBESTMEMECOIN281Src:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/abd7273e-a1d8-480a-a685-990544bc078e/176cae81-82f4-4a0b-9dd7-94401f078f6f?org_if_sml=13074&force_format=original',
  iAMTHEBESTMEMECOIN241Alt: 'IAMTHEBESTMEMECOIN2411302',
}

Baspage.propTypes = {
  rectangle9Src2: PropTypes.string,
  rootClassName: PropTypes.string,
  rectangle10Alt: PropTypes.string,
  rectangle10Alt1: PropTypes.string,
  linkIAMTHEBESTMEMECOIN241: PropTypes.string,
  rectangle10Src2: PropTypes.string,
  rectangle9Alt1: PropTypes.string,
  text5: PropTypes.string,
  linkRectangle10: PropTypes.string,
  text2: PropTypes.string,
  rectangle9Alt: PropTypes.string,
  rectangle9Src: PropTypes.string,
  rectangle9Alt2: PropTypes.string,
  text: PropTypes.string,
  iAMTHEBESTMEMECOIN251Alt: PropTypes.string,
  tAPEAlt: PropTypes.string,
  text4: PropTypes.string,
  text3: PropTypes.string,
  rectangle10Alt2: PropTypes.string,
  rectangle10Src1: PropTypes.string,
  iAMTHEBESTMEMECOIN251Src: PropTypes.string,
  rectangle10Src: PropTypes.string,
  iAMTHEBESTMEMECOIN281Alt: PropTypes.string,
  tAPESrc: PropTypes.string,
  iAMTHEBESTMEMECOIN241Src: PropTypes.string,
  text1: PropTypes.string,
  linkIAMTHEBESTMEMECOIN281: PropTypes.string,
  linkRectangle9: PropTypes.string,
  linkIAMTHEBESTMEMECOIN251: PropTypes.string,
  rectangle9Src1: PropTypes.string,
  linkRectangle101: PropTypes.string,
  mail: PropTypes.string,
  iAMTHEBESTMEMECOIN281Src: PropTypes.string,
  iAMTHEBESTMEMECOIN241Alt: PropTypes.string,
}

export default Baspage
