import React from 'react'

import PropTypes from 'prop-types'

import './cta1.css'

const CTA1 = (props) => {
  return (
    <div
      className={`cta1-container thq-section-padding ${props.rootClassName} `}
    >
      <div className="cta1-max-width thq-section-max-width">
        <div className="cta1-content"></div>
      </div>
    </div>
  )
}

CTA1.defaultProps = {
  content1:
    'Become a part of the Whisker community and stay updated on the latest developments, NFT drops, and exciting gameplay opportunities.',
  rootClassName: '',
  heading1: 'Ready to dive into the world of memes?',
  action1: 'Home',
  action2: 'Explore Whisker Marketplace',
}

CTA1.propTypes = {
  content1: PropTypes.string,
  rootClassName: PropTypes.string,
  heading1: PropTypes.string,
  action1: PropTypes.string,
  action2: PropTypes.string,
}

export default CTA1
