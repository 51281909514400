import React from 'react'

import PropTypes from 'prop-types'

import './content-list1.css'

const ContentList1 = (props) => {
  return (
    <div
      className={`content-list1-container thq-section-padding ${props.rootClassName} `}
    >
      <div className="content-list1-max-width thq-section-max-width">
        <div className="content-list1-content thq-flex-column">
          <ul className="thq-flex-column">
            <li className="content-list1-li list-item thq-flex-column">
              <h2 className="content-list1-heading1 thq-heading-2">
                {props.heading1}
              </h2>
              <p className="content-list1-content1 thq-body-small">
                <span className="">Effective date: May 13nd, 2024</span>
                <br className="content-list1-text001"></br>
                <br className=""></br>
                <span className="">
                  Whisker Game (&quot;us&quot;, &quot;we&quot;, or
                  &quot;our&quot;) operates the website.
                </span>
                <br className="content-list1-text004"></br>
                <br className=""></br>
                <span className="">
                  This page informs you of our policies regarding the
                  collection, use, and disclosure of personal data when you use
                  our Service and the choices you have associated with that
                  data.
                </span>
                <br className="content-list1-text007"></br>
                <br className=""></br>
                <span className="">
                  We use your data to provide and improve the Service. By using
                  the Service, you agree to the collection and use of
                  information in accordance with this policy. Unless otherwise
                  defined in this Privacy Policy, terms used in this Privacy
                  Policy have the same meanings as in our Terms and Conditions.
                </span>
                <br className="content-list1-text010"></br>
                <br className=""></br>
                <span className="">
                  I. INFORMATION COLLECTION AND USE We collect several different
                  types of information for various purposes to provide and
                  improve our Service to you.
                </span>
                <br className="content-list1-text013"></br>
                <br className=""></br>
                <span className="">
                  If you do not use (log in to) the service for two years all
                  your personal data will be deleted.
                </span>
                <br className="content-list1-text016"></br>
                <br className=""></br>
                <span className="">Types of Data Collected:</span>
                <br className="content-list1-text019"></br>
                <br className=""></br>
                <span className="">
                  1. Personal Data While using our Service, we may ask you to
                  provide us with certain personally identifiable information
                  that can be used to contact or identify you (&quot;Personal
                  Data&quot;). Personally identifiable information may include,
                  but is not limited to:
                </span>
                <br className="content-list1-text022"></br>
                <br className=""></br>
                <span className="">Email address Cookies and Usage Data</span>
                <br className="content-list1-text025"></br>
                <br className=""></br>
                <span className="">
                  2. Usage Data We may also collect information that your
                  browser sends whenever you visit our Service or when you
                  access the Service by or through a mobile device (&quot;Usage
                  Data&quot;).
                </span>
                <br className="content-list1-text028"></br>
                <br className=""></br>
                <span className="">
                  This Usage Data may include information such as your
                  computer&apos;s Internet Protocol address (e.g. IP address),
                  browser type, browser version, the pages of our Service that
                  you visit, the time and date of your visit, the time spent on
                  those pages, unique device identifiers and other diagnostic
                  data.
                </span>
                <br className="content-list1-text031"></br>
                <br className=""></br>
                <span className="">
                  When you access the Service by or through a mobile device,
                  this Usage Data may include information such as the type of
                  mobile device you use, your mobile device unique ID, the IP
                  address of your mobile device, your mobile operating system,
                  the type of mobile Internet browser you use, unique device
                  identifiers and other diagnostic data.
                </span>
                <br className="content-list1-text034"></br>
                <br className=""></br>
                <span className="">
                  3. Tracking Cookies Data We use cookies and similar tracking
                  technologies to track the activity on our Service and hold
                  certain information.
                </span>
                <br className="content-list1-text037"></br>
                <br className=""></br>
                <span className="">
                  Cookies are files with small amount of data which may include
                  an anonymous unique identifier. Cookies are sent to your
                  browser from a website and stored on your device. Tracking
                  technologies also used are beacons, tags, and scripts to
                  collect and track information and to improve and analyze our
                  Service.
                </span>
                <br className="content-list1-text040"></br>
                <br className=""></br>
                <span className="">
                  You can instruct your browser to refuse all cookies or to
                  indicate when a cookie is being sent. However, if you do not
                  accept cookies, you may not be able to use some portions of
                  our Service.
                </span>
                <br className="content-list1-text043"></br>
                <br className=""></br>
                <span className="">Examples of Cookies we use:</span>
                <br className="content-list1-text046"></br>
                <br className=""></br>
                <span className="">
                  Session Cookies. We use Session Cookies to operate our
                  Service. Preference Cookies. We use Preference Cookies to
                  remember your preferences and various settings. Security
                  Cookies. We use Security Cookies for security purposes.
                </span>
                <br className="content-list1-text049"></br>
                <br className=""></br>
                <span className="">
                  II. USE OF DATA Whisker Game uses the collected data for
                  various purposes:
                </span>
                <br className="content-list1-text052"></br>
                <br className=""></br>
                <span className="">
                  To provide and maintain the Service To notify you about
                  changes to our Service To allow you to participate in
                  interactive features of our Service when you choose to do so
                  To provide customer care and support To provide analysis or
                  valuable information so that we can improve the Service To
                  monitor the usage of the Service To detect, prevent and
                  address technical issues
                </span>
                <br className="content-list1-text055"></br>
                <br className=""></br>
                <span className="">
                  III. TRANSFER OF DATA Your information, including Personal
                  Data, may be transferred to — and maintained on — computers
                  located outside of your state, province, country or other
                  governmental jurisdiction where the data protection laws may
                  differ than those from your jurisdiction.
                </span>
                <br className="content-list1-text058"></br>
                <br className=""></br>
                <span className="">
                  If you are located outside United States and choose to provide
                  information to us, please note that we transfer the data,
                  including Personal Data, to United States and process it
                  there.
                </span>
                <br className="content-list1-text061"></br>
                <br className=""></br>
                <span className="">
                  Your consent to this Privacy Policy followed by your
                  submission of such information represents your agreement to
                  that transfer.
                </span>
                <br className="content-list1-text064"></br>
                <br className=""></br>
                <span className="">
                  Whisker Game will take all steps reasonably necessary to
                  ensure that your data is treated securely and in accordance
                  with this Privacy Policy and no transfer of your Personal Data
                  will take place to an organization or a country unless there
                  are adequate controls in place including the security of your
                  data and other personal information
                </span>
                <br className="content-list1-text067"></br>
                <br className=""></br>
                <span className="">
                  IV. DISCLOSURE OF DATA Legal Requirements Whisker Game may
                  disclose your Personal Data in the good faith belief that such
                  action is necessary to:
                </span>
                <br className="content-list1-text070"></br>
                <br className=""></br>
                <span className="">
                  To comply with a legal obligation To protect and defend the
                  rights or property of Whisker Game To prevent or investigate
                  possible wrongdoing in connection with the Service To protect
                  the personal safety of users of the Service or the public To
                  protect against legal liability
                </span>
                <br className="content-list1-text073"></br>
                <br className=""></br>
                <span className="">
                  V. SECURITY OF DATA The security of your data is important to
                  us, but remember that no method of transmission over the
                  Internet, or method of electronic storage is 100% secure.
                  While we strive to use commercially acceptable means to
                  protect your Personal Data, we cannot guarantee its absolute
                  security.
                </span>
                <br className="content-list1-text076"></br>
                <br className=""></br>
                <span className="">
                  VI. SERVICE PROVIDERS We may employ third party companies and
                  individuals to facilitate our Service (&quot;Service
                  Providers&quot;), to provide the Service on our behalf, to
                  perform Service-related services or to assist us in analyzing
                  how our Service is used.
                </span>
                <br className="content-list1-text079"></br>
                <br className=""></br>
                <span className="">
                  These third parties have access to your Personal Data only to
                  perform these tasks on our behalf and are obligated not to
                  disclose or use it for any other purpose.
                </span>
                <br className="content-list1-text082"></br>
                <br className=""></br>
                <span className="">
                  Analytics We may use third-party Service Providers to monitor
                  and analyze the use of our Service.
                </span>
                <br className="content-list1-text085"></br>
                <br className=""></br>
                <span className="">
                  VII. LINKS TO OTHER SITES Our Service may contain links to
                  other sites that are not operated by us. If you click on a
                  third party link, you will be directed to that third
                  party&apos;s site. We strongly advise you to review the
                  Privacy Policy of every site you visit.
                </span>
                <br className="content-list1-text088"></br>
                <br className=""></br>
                <span className="">
                  We have no control over and assume no responsibility for the
                  content, privacy policies or practices of any third party
                  sites or services.
                </span>
                <br className="content-list1-text091"></br>
                <br className=""></br>
                <span className="">
                  VIII. CHILDREN&apos;S PRIVACY Our Service does not address
                  anyone under the age of 18 (&quot;Children&quot;).
                </span>
                <br className="content-list1-text094"></br>
                <br className=""></br>
                <span className="">
                  We do not knowingly collect personally identifiable
                  information from anyone under the age of 18. If you are a
                  parent or guardian and you are aware that your Children has
                  provided us with Personal Data, please contact us. If we
                  become aware that we have collected Personal Data from
                  children without verification of parental consent, we take
                  steps to remove that information from our servers.
                </span>
                <br className="content-list1-text097"></br>
                <br className=""></br>
                <span className="">
                  IX. CHANGES TO THIS PRIVACY POLICY We may update our Privacy
                  Policy from time to time. We will notify you of any changes by
                  posting the new Privacy Policy on this page.
                </span>
                <br className="content-list1-text100"></br>
                <br className=""></br>
                <span className="">
                  We will let you know via email and/or a prominent notice on
                  our Service, prior to the change becoming effective and update
                  the &quot;effective date&quot; at the top of this Privacy
                  Policy.
                </span>
                <br className="content-list1-text103"></br>
                <br className=""></br>
                <span className="">
                  You are advised to review this Privacy Policy periodically for
                  any changes. Changes to this Privacy Policy are effective when
                  they are posted on this page.
                </span>
                <br className="content-list1-text106"></br>
                <br className=""></br>
                <span className="">
                  X. CONTACT US If you have any questions about this Privacy
                  Policy, please contact us:
                </span>
                <br className="content-list1-text109"></br>
                <br className=""></br>
                <span className="">Email: Contact@witwhisker.com</span>
                <br className="content-list1-text112"></br>
                <br className=""></br>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

ContentList1.defaultProps = {
  heading1: 'Privacy Policy',
  rootClassName: '',
}

ContentList1.propTypes = {
  heading1: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default ContentList1
