import React from 'react'

import PropTypes from 'prop-types'

import './tokenomics.css'

const TOKENOMICS = (props) => {
  return (
    <div
      id="TOKENOMICS"
      className={`tokenomics-tokenomics ${props.rootClassName} `}
    >
      <div className="tokenomics-group11">
        <img
          alt={props.rectangle3Alt}
          src={props.rectangle3Src}
          className="tokenomics-rectangle3"
        />
        <img
          alt={props.rectangle4Alt}
          src={props.rectangle4Src}
          className="tokenomics-rectangle4"
        />
        <img
          alt={props.tokenmics1Alt}
          src={props.tokenmics1Src}
          className="tokenomics-tokenmics1"
        />
        <div className="tokenomics-presale">
          <img
            alt={props.ellipse1Alt}
            src={props.ellipse1Src}
            className="tokenomics-ellipse1"
          />
          <span className="tokenomics-text">{props.text1}</span>
          <span className="tokenomics-text01">{props.text2}</span>
          <span className="tokenomics-text02">{props.text3}</span>
          <span className="tokenomics-text03">{props.text4}</span>
        </div>
        <div className="tokenomics-dev">
          <img
            alt={props.ellipse1Alt1}
            src={props.ellipse1Src1}
            className="tokenomics-ellipse11"
          />
          <span className="tokenomics-text04">{props.text5}</span>
          <span className="tokenomics-text05">{props.text6}</span>
          <span className="tokenomics-text06">{props.text7}</span>
          <span className="tokenomics-text07">{props.text8}</span>
        </div>
        <div className="tokenomics-cex">
          <img
            alt={props.ellipse1Alt2}
            src={props.ellipse1Src2}
            className="tokenomics-ellipse12"
          />
          <span className="tokenomics-text08">{props.text9}</span>
          <span className="tokenomics-text09">{props.text10}</span>
          <span className="tokenomics-text10">{props.text11}</span>
          <span className="tokenomics-text11">{props.text12}</span>
        </div>
        <div className="tokenomics-airdrop">
          <img
            alt={props.ellipse1Alt3}
            src={props.ellipse1Src3}
            className="tokenomics-ellipse13"
          />
          <span className="tokenomics-text12">{props.text13}</span>
          <span className="tokenomics-text13">{props.text14}</span>
        </div>
        <div className="tokenomics-m-arketing">
          <img
            alt={props.ellipse1Alt4}
            src={props.ellipse1Src4}
            className="tokenomics-ellipse14"
          />
          <span className="tokenomics-text14">{props.text15}</span>
          <span className="tokenomics-text15">{props.text16}</span>
        </div>
        <div className="tokenomics-game">
          <img
            alt={props.ellipse1Alt5}
            src={props.ellipse1Src5}
            className="tokenomics-ellipse15"
          />
          <span className="tokenomics-text16">{props.text17}</span>
          <span className="tokenomics-text17">{props.text18}</span>
        </div>
        <div className="tokenomics-forlp">
          <span className="tokenomics-text18">{props.text19}</span>
          <span className="tokenomics-text19">{props.text20}</span>
          <img
            alt={props.ellipse1Alt6}
            src={props.ellipse1Src6}
            className="tokenomics-ellipse16"
          />
        </div>
        <span className="tokenomics-text20">{props.text}</span>
      </div>
    </div>
  )
}

TOKENOMICS.defaultProps = {
  text2: '30 % For Presale :',
  text3: '- 5% In Airdrop',
  ellipse1Alt6: 'Ellipse11303',
  rectangle4Alt: 'Rectangle41303',
  text12: '- 5% for Market Making',
  ellipse1Src4: '/images/ellipse11303-qgo-200h.png',
  ellipse1Alt2: 'Ellipse11303',
  text9: '15 % for Exchanges  :',
  tokenmics1Src: '/images/tokenmics11303-415e-600w.png',
  text14: '15 % for Airdrops(P2A)',
  text11: '- 10% for CEX',
  text20: '15 % for LP',
  text7: '- 2% Developer Whisker BETA',
  ellipse1Alt3: 'Ellipse11303',
  text19: '15 % for LP',
  ellipse1Src5: '/images/ellipse11303-dkx-200h.png',
  text4: '- 25% In Airdrop',
  rootClassName: '',
  text13: '15 % for Airdrops(P2A)',
  text10: '15 % for Exchanges :',
  ellipse1Src1: '/images/ellipse11303-3hb7-200h.png',
  text1: '30 % For Presale :',
  ellipse1Src: '/images/ellipse11303-5blc-200h.png',
  ellipse1Alt4: 'Ellipse11303',
  rectangle4Src: '/images/rectangle41303-bzfq-1100h.png',
  ellipse1Alt1: 'Ellipse11303',
  text18: '10 % for Whisker Game',
  text5: '10 % For Developer :',
  text15: '5 % for Marketing',
  text6: '10 % For Developer :',
  text16: '5 % for Marketing',
  text8: '- 8% Developer Whisker GAME',
  rectangle3Src: '/images/rectangle31302-zyqj-1100h.png',
  ellipse1Alt5: 'Ellipse11303',
  tokenmics1Alt: 'tokenmics11303',
  rectangle3Alt: 'Rectangle31302',
  ellipse1Src6: '/images/ellipse11303-bbve.svg',
  ellipse1Alt: 'Ellipse11303',
  text: 'TOKENOMICS : 1B',
  ellipse1Src2: '/images/ellipse11303-jvk-200h.png',
  text17: '10 % for Whisker Game',
  ellipse1Src3: '/images/ellipse11303-2lob-200h.png',
}

TOKENOMICS.propTypes = {
  text2: PropTypes.string,
  text3: PropTypes.string,
  ellipse1Alt6: PropTypes.string,
  rectangle4Alt: PropTypes.string,
  text12: PropTypes.string,
  ellipse1Src4: PropTypes.string,
  ellipse1Alt2: PropTypes.string,
  text9: PropTypes.string,
  tokenmics1Src: PropTypes.string,
  text14: PropTypes.string,
  text11: PropTypes.string,
  text20: PropTypes.string,
  text7: PropTypes.string,
  ellipse1Alt3: PropTypes.string,
  text19: PropTypes.string,
  ellipse1Src5: PropTypes.string,
  text4: PropTypes.string,
  rootClassName: PropTypes.string,
  text13: PropTypes.string,
  text10: PropTypes.string,
  ellipse1Src1: PropTypes.string,
  text1: PropTypes.string,
  ellipse1Src: PropTypes.string,
  ellipse1Alt4: PropTypes.string,
  rectangle4Src: PropTypes.string,
  ellipse1Alt1: PropTypes.string,
  text18: PropTypes.string,
  text5: PropTypes.string,
  text15: PropTypes.string,
  text6: PropTypes.string,
  text16: PropTypes.string,
  text8: PropTypes.string,
  rectangle3Src: PropTypes.string,
  ellipse1Alt5: PropTypes.string,
  tokenmics1Alt: PropTypes.string,
  rectangle3Alt: PropTypes.string,
  ellipse1Src6: PropTypes.string,
  ellipse1Alt: PropTypes.string,
  text: PropTypes.string,
  ellipse1Src2: PropTypes.string,
  text17: PropTypes.string,
  ellipse1Src3: PropTypes.string,
}

export default TOKENOMICS
