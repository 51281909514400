import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import './ambassador.css'

const Ambassador = (props) => {
  return (
    <div className="ambassador-container">
      <Helmet>
        <title>ambassador - exported project</title>
        <meta property="og:title" content="ambassador - exported project" />
      </Helmet>
      <div className="ambassador-frame2">
        <span className="ambassador-text">
          WHISKER
          <span
            dangerouslySetInnerHTML={{
              __html: ' ',
            }}
          />
        </span>
        <span className="ambassador-text01">AMBASSADOR</span>
        <div className="ambassador-container1">
          <img
            src="/barre2312-1lkk-200h.png"
            alt="Barre2312"
            className="ambassador-barre"
          />
          <img
            src="/designsanstitre3222312-py8k-1000w.png"
            alt="Designsanstitre3222312"
            className="ambassador-designsanstitre322"
          />
        </div>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSfozakzOYZ6mNgblkIvi04JNEMCfhluIyQ4T1SfXnF6wqtcCA/viewform?usp=sf_link"
          target="_blank"
          rel="noreferrer noopener"
          className="ambassador-link"
        >
          <div className="ambassador-group2">
            <img
              src="/whisker4212318-2xt7-200h.png"
              alt="WHISKER4212318"
              className="ambassador-whisker421"
            />
            <span className="ambassador-text02">
              <span>Join us</span>
            </span>
          </div>
        </a>
        <Navbar rootClassName="navbar-root-class-name5"></Navbar>
      </div>
      <div className="ambassador-frame3">
        <img
          src="/wit2312-an5o-500w.png"
          alt="WIT2312"
          className="ambassador-wit"
        />
        <span className="ambassador-text04">
          <span>
            This program is designed to Boost the launch of Whisker, our launch
            is coming.
          </span>
          <br className="ambassador-text06"></br>
          <br></br>
          <span>We will do everything to make it a success!</span>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <span>
            This program will last approximately 1 month, and the best among you
            will have the opportunity to become a full-time ambassador with a
            fixed salary.
          </span>
        </span>
        <img
          src="/whisker4112315-rm5p-1000h.png"
          alt="WHISKER4112315"
          className="ambassador-whisker411"
        />
      </div>
      <div className="ambassador-frame4">
        <div className="ambassador-container2">
          <img
            src="/whisker%20(43)%201-1500w.png"
            alt="image"
            className="ambassador-image"
          />
          <img
            src="/gain-1500w.png"
            alt="image"
            className="ambassador-image1"
          />
        </div>
        <span className="ambassador-text14">Task and reward</span>
      </div>
      <div className="ambassador-frame5">
        <span className="ambassador-text15">
          <span>
            This program promises to reward the 60% best ambassador in the event
            of a launch, the Whisker team is doing everything possible to have
            an effective launch, for this we need you and your will.
          </span>
          <br className="ambassador-text17"></br>
          <br></br>
          <span>
            We are close to the launch, we expect it within 1 month, but the
            date is not certain.
          </span>
        </span>
        <span className="ambassador-text20">WARNING</span>
        <img
          src="/rectangle72316-8grc-200h.png"
          alt="Rectangle72316"
          className="ambassador-rectangle7"
        />
      </div>
      <div className="ambassador-frame6">
        <span className="ambassador-text21">
          CONTACT US AND JOIN THE FUTURE
        </span>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSfozakzOYZ6mNgblkIvi04JNEMCfhluIyQ4T1SfXnF6wqtcCA/viewform?usp=sf_link"
          target="_blank"
          rel="noreferrer noopener"
          className="ambassador-link1"
        >
          <div className="ambassador-group3">
            <img
              src="/whisker4212318-y9jj-200h.png"
              alt="WHISKER4212318"
              className="ambassador-whisker4211"
            />
            <span className="ambassador-text22">
              <span>Join us</span>
            </span>
          </div>
        </a>
      </div>
    </div>
  )
}

export default Ambassador
