import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import CTA1 from '../components/cta1'
import ContentList1 from '../components/content-list1'
import Baspage from '../components/baspage'
import './privacypolicy.css'

const Privacypolicy = (props) => {
  return (
    <div className="privacypolicy-container">
      <Helmet>
        <title>privacypolicy - exported project</title>
        <meta property="og:title" content="privacypolicy - exported project" />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name3"></Navbar>
      <div className="privacypolicy-cta2">
        <CTA1 rootClassName="cta1-root-class-name"></CTA1>
      </div>
      <div className="privacypolicy-contentlist3">
        <ContentList1 rootClassName="content-list1-root-class-name"></ContentList1>
      </div>
      <Baspage rootClassName="baspage-root-class-name2"></Baspage>
      <div className="privacypolicy-footer4"></div>
    </div>
  )
}

export default Privacypolicy
