import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import './presale.css'

const Presale = (props) => {
  return (
    <div className="presale-container">
      <Helmet>
        <title>exported project</title>
      </Helmet>
      <div className="presale-frame38">
        <div className="presale-container1">
          <div className="presale-container2"></div>
        </div>
        <Navbar rootClassName="navbar-root-class-name2"></Navbar>
        <img
          alt="image"
          src="/design%20sans%20titre%20(16)-1100h.png"
          className="presale-image"
        />
        <img
          alt="image"
          src="/whisker%20(35)%201-1500w.png"
          className="presale-image1"
        />
        <div className="presale-container3">
          <div className="presale-container4">
            <div className="presale-container5">
              <div className="presale-group32">
                <img
                  alt="Rectangle141331"
                  src="/rectangle141331-klb.svg"
                  className="presale-rectangle14"
                />
                <img
                  alt="Rectangle151331"
                  src="/rectangle151331-21gg.svg"
                  className="presale-rectangle15"
                />
                <div className="presale-container6">
                  <img
                    alt="Rectangle121331"
                    src="/rectangle121331-429-200h.png"
                    className="presale-rectangle12"
                  />
                  <img
                    alt="Rectangle131331"
                    src="/rectangle131331-8eb-200h.png"
                    className="presale-rectangle13"
                  />
                  <span className="presale-text">
                    <span>COPY</span>
                  </span>
                  <span className="presale-text02">
                    <span>COPY</span>
                  </span>
                </div>
                <span className="presale-text04">
                  <span>SOON</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="presale-group28">
          <img
            alt="WHISKER1211331"
            src="/whisker1211331-de5p-1000w.png"
            className="presale-whisker121"
          />
          <img
            alt="whiskerlogooffbois11331"
            src="/whiskerlogooffbois11331-rcsc-200h.png"
            className="presale-whiskerlogooffbois1"
          />
          <div className="presale-group6">
            <img
              alt="Rectangle21332"
              src="/rectangle21332-jufyh-200h.png"
              className="presale-rectangle2"
            />
            <span className="presale-text06">
              <span>PRESALE</span>
            </span>
          </div>
          <span className="presale-text08">
            <span>MARKETPLACE</span>
          </span>
          <span className="presale-text10">
            <span>ROADMAP</span>
          </span>
          <span className="presale-text12">
            <span>TOKENOMICS</span>
          </span>
          <span className="presale-text14">
            <span>SOCIAL</span>
          </span>
          <span className="presale-text16">
            <span>NEWS</span>
          </span>
        </div>
        <div id="SOC" className="presale-low-page">
          <img
            alt="image"
            src="/whisker%20taupe-1000h.png"
            className="presale-taupe"
          />
          <div className="presale-bigwhisker"></div>
          <span className="presale-text18">JOIN THE $WIT COMMUNITY</span>
          <div className="presale-group5">
            <a
              href="https://twitter.com/Whisker_Witcoin"
              target="_blank"
              rel="noreferrer noopener"
              className="presale-link"
            >
              <div className="presale-x">
                <img
                  alt="IAMTHEBESTMEMECOIN241347"
                  src="/iamthebestmemecoin241347-zx2g-200h.png"
                  className="presale-iamthebestmemecoin241"
                />
              </div>
            </a>
            <a
              href="https://discord.com/invite/unjG6yz4T3"
              target="_blank"
              rel="noreferrer noopener"
              className="presale-link1"
            >
              <div className="presale-discord">
                <img
                  alt="IAMTHEBESTMEMECOIN281343"
                  src="/iamthebestmemecoin281343-np7w-200h.png"
                  className="presale-iamthebestmemecoin281"
                />
              </div>
            </a>
            <a
              href="https://t.me/+fcFRXCCEWPI0Y2U0"
              target="_blank"
              rel="noreferrer noopener"
              className="presale-link2"
            >
              <div className="presale-telegram">
                <img
                  alt="IAMTHEBESTMEMECOIN251345"
                  src="/iamthebestmemecoin251345-t6-200h.png"
                  className="presale-iamthebestmemecoin251"
                />
              </div>
            </a>
          </div>
          <a
            href="mailto:contact@witwhisker.com?subject=For the best GameFi Project On BASE"
            className="presale-text19"
          >
            Contact@WitWhisker.com
          </a>
          <div className="presale-group61">
            <Link to="/privacypolicy" className="presale-text20">
              <span>PrivacyPolicy</span>
            </Link>
            <a
              href="https://witwhisker.com/PDFWP.pdf"
              target="_blank"
              rel="noreferrer noopener"
              className="presale-text22"
            >
              <span>WhitePaper</span>
            </a>
            <span className="presale-text24">
              <span>Terms of Use</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Presale
