import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import TOKENOMICS from '../components/tokenomics'
import TeleportHQFooter from '../components/teleport-hq-footer'
import './home-fuild.css'

const HomeFuild = (props) => {
  return (
    <div className="home-fuild-container">
      <Helmet>
        <title>exported project</title>
        <meta property="og:title" content="exported project" />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name4"></Navbar>
      <div className="home-fuild-hero">
        <img
          alt="image"
          src="/Homepage/back%201%20(2)-1500w.png"
          className="home-fuild-backtele"
        />
        <img
          alt="image"
          src="/Homepage/whisker%20(36)-1500w.png"
          className="home-fuild-back"
        />
        <img
          alt="image"
          src="/Homepage/design%20sans%20titre%20(8)%2021-1500w.png"
          className="home-fuild-herbe"
        />
        <img
          alt="image"
          src="/Homepage/design%20sans%20titre%20(8)%201%20(1)-1500w.png"
          className="home-fuild-herbetele"
        />
        <img
          alt="image"
          src="/whisker-beta-15-05-2024-500h.png"
          className="home-fuild-whisker-beta"
        />
        <img
          alt="image"
          src="/Homepage/nuage%201-1500w.png"
          className="home-fuild-nuage"
        />
      </div>
      <div className="home-fuild-section1">
        <div className="home-fuild-whiskers">
          <div className="home-fuild-whisker1">
            <h1 className="home-fuild-text">MINT YOUR NFT</h1>
            <span className="home-fuild-text01">
              Whisker plans to create 9  collections for a total  of 13,900 NFTs
              at the end of  the game cycle.
            </span>
            <Link to="/marketplace" className="home-fuild-navlink">
              <span className="home-fuild-text02">MINT</span>
            </Link>
          </div>
          <div className="home-fuild-whisker2">
            <h1 className="home-fuild-text03">PLAY TO AIRDROP</h1>
            <span className="home-fuild-text04">
              <span>15% of our supply will be distributed</span>
              <br></br>
              <span> to the player.</span>
              <br></br>
              <br></br>
              <span>NFTs are GamePasses.</span>
              <br></br>
              <span>Travel to 9 different eras to</span>
              <br></br>
              <span> reap maximum winnings!</span>
            </span>
            <Link to="/marketplace" className="home-fuild-navlink1">
              <span className="home-fuild-text15">PLAY</span>
            </Link>
          </div>
        </div>
        <div className="home-fuild-etager">
          <img
            alt="image"
            src="/Homepage/etager%202-1500w.png"
            className="home-fuild-image"
          />
          <div className="home-fuild-container1">
            <img
              alt="image"
              src="/whisker%20(29)-200h.png"
              className="home-fuild-image1"
            />
            <img
              alt="image"
              src="/whisker%20(29)-200h.png"
              className="home-fuild-image2"
            />
            <img
              alt="image"
              src="/whisker%20(29)-200h.png"
              className="home-fuild-image3"
            />
            <img
              alt="image"
              src="/whisker%20(29)-200h.png"
              className="home-fuild-image4"
            />
            <img
              alt="image"
              src="/whisker%20(29)-200h.png"
              className="home-fuild-image5"
            />
          </div>
        </div>
      </div>
      <div className="home-fuild-section2">
        <div className="home-fuild-container2">
          <img
            alt="image"
            src="/Homepage/what-4600h.png"
            className="home-fuild-image6"
          />
          <img
            alt="image"
            src="/Homepage/old-600w.png"
            className="home-fuild-image7"
          />
        </div>
        <div className="home-fuild-container3">
          <div className="home-fuild-container4">
            <span className="home-fuild-text16">
              <span>Cat news</span>
            </span>
            <animate-on-reveal
              animation="none"
              duration="300ms"
              delay="0s"
              direction="normal"
              easing="ease"
              iteration="1"
            >
              <a
                href="https://medium.com/@witcoinwhisker"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img
                  alt="pancartediught11302"
                  src="/images/pancartediught11302-100g-800w.png"
                  data-thq-animate-on-reveal="true"
                  className="home-fuild-pancartediught1"
                />
              </a>
            </animate-on-reveal>
          </div>
          <animate-on-reveal
            animation="pulse"
            duration="500ms"
            delay="0s"
            direction="normal"
            easing="linear"
            iteration="1"
          >
            <a
              href="https://witwhisker.com/FAQ/en/faq.html"
              target="_blank"
              rel="noreferrer noopener"
            >
              <div
                data-thq-animate-on-reveal="true"
                className="home-fuild-group10"
              >
                <img
                  alt="IAMTHEBESTMEMECOIN1511302"
                  src="/images/iamthebestmemecoin1511302-gig-300h.png"
                  className="home-fuild-iamthebestmemecoin151"
                />
                <span className="home-fuild-text18">FAQ</span>
              </div>
            </a>
          </animate-on-reveal>
        </div>
        <TOKENOMICS rootClassName="tokenomics-root-class-name1"></TOKENOMICS>
      </div>
      <div id="TOK" className="home-fuild-bar"></div>
      <div className="home-fuild-low-page">
        <div id="RD" className="home-fuild-cards-container">
          <div className="home-fuild-card">
            <span className="home-fuild-text19">Q1</span>
            <div className="home-fuild-content">
              <span className="home-fuild-text20">
                <span>-Develop our different networks</span>
              </span>
              <span className="home-fuild-text22">
                <span>-Creation Whisker BETA</span>
              </span>
              <span className="home-fuild-text24">
                <span>-Release of our first NFT collection</span>
              </span>
              <span className="home-fuild-text26">
                <span>
                  -Launch our Presale to raise
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span> between1 </span>
                <span>and 4 Million</span>
              </span>
            </div>
          </div>
          <div className="home-fuild-card1">
            <span className="home-fuild-text31">Q3</span>
            <div className="home-fuild-frame5">
              <span className="home-fuild-text32">
                <span>-Creation Whisker GAME</span>
              </span>
              <span className="home-fuild-text34">
                <span>
                  -Strategic partnership with video  game institutions.
                </span>
              </span>
              <span className="home-fuild-text36">
                <span>-Growing in the metaverse</span>
              </span>
            </div>
          </div>
          <div className="home-fuild-card2">
            <span className="home-fuild-text38">Q2</span>
            <div className="home-fuild-frame51">
              <span className="home-fuild-text39">
                <span>- Collect strategic partnerships  with memecoin</span>
              </span>
              <span className="home-fuild-text41">
                <span>- Complete all 9 Whisker BETA  worlds</span>
              </span>
              <span className="home-fuild-text43">
                <span>-Finish all 9 3D NFT collections</span>
              </span>
            </div>
          </div>
        </div>
        <div id="SOC" className="home-fuild-low-page1">
          <img
            alt="image"
            src="/whisker%20taupe-1000h.png"
            className="home-fuild-taupe"
          />
          <div className="home-fuild-bigwhisker"></div>
          <span className="home-fuild-text45">JOIN THE $WIT COMMUNITY</span>
          <div className="home-fuild-group5">
            <a
              href="https://twitter.com/Whisker_Witcoin"
              target="_blank"
              rel="noreferrer noopener"
              className="home-fuild-link2"
            >
              <div className="home-fuild-x">
                <img
                  alt="IAMTHEBESTMEMECOIN241347"
                  src="/iamthebestmemecoin241347-zx2g-200h.png"
                  className="home-fuild-iamthebestmemecoin241"
                />
              </div>
            </a>
            <a
              href="https://discord.com/invite/unjG6yz4T3"
              target="_blank"
              rel="noreferrer noopener"
              className="home-fuild-link3"
            >
              <div className="home-fuild-discord">
                <img
                  alt="IAMTHEBESTMEMECOIN281343"
                  src="/iamthebestmemecoin281343-np7w-200h.png"
                  className="home-fuild-iamthebestmemecoin281"
                />
              </div>
            </a>
            <a
              href="https://t.me/+fcFRXCCEWPI0Y2U0"
              target="_blank"
              rel="noreferrer noopener"
              className="home-fuild-link4"
            >
              <div className="home-fuild-telegram">
                <img
                  alt="IAMTHEBESTMEMECOIN251345"
                  src="/iamthebestmemecoin251345-t6-200h.png"
                  className="home-fuild-iamthebestmemecoin251"
                />
              </div>
            </a>
          </div>
          <a
            href="mailto:contact@witwhisker.com?subject=For the best GameFi Project On BASE"
            className="home-fuild-text46"
          >
            Contact@WitWhisker.com
          </a>
          <div className="home-fuild-group6">
            <Link to="/privacypolicy" className="home-fuild-text47">
              <span>PrivacyPolicy</span>
            </Link>
            <a
              href="https://witwhisker.com/PDFWP.pdf"
              target="_blank"
              rel="noreferrer noopener"
              className="home-fuild-text49"
            >
              <span>WhitePaper</span>
            </a>
            <span className="home-fuild-text51">
              <span>Terms of Use</span>
            </span>
          </div>
        </div>
      </div>
      <TeleportHQFooter></TeleportHQFooter>
    </div>
  )
}

export default HomeFuild
